











































import { Component, Prop, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { Counters, SavedTaskItem, UID, UnsavedTaskItems } from './types'

@Component({
  components: {
    draggable,
    Item: () => import('./Item.vue'),
    Deleted: () => import('./Deleted.vue'),
  },
})
export default class List extends Vue {
  @Prop({ type: Array, required: true }) readonly items!: SavedTaskItem[]
  @Prop({ type: Object, default: () => {} }) readonly counters!: Counters
  @Prop({ type: Boolean, required: true }) readonly canEdit!: boolean
  @Prop({ type: Array, default: () => [] }) readonly deletedItems!: UID[]
  @Prop({ type: Array, default: () => [] }) readonly loadingItems!: UID[]
  @Prop({ type: String }) readonly editingItem?: UID
  @Prop({
    type: Object,
    default: () => {},
  }) readonly unsavedItems!: UnsavedTaskItems
}
